/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import HomeService from './services/home.service'

document.addEventListener('DOMContentLoaded', () => {
    new HomeService()
})
