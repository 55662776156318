import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class HomeService {
    static autoplayTimer

    constructor() {
        if ($('.home').length > 0) {
            HomeService.header().then(() => {
                HomeService.strateBases()
                HomeService.strateActivites()
                HomeService.strateChiffres()
                HomeService.strateBlog()
                HomeService.scrollReveal()
            })
        }
    }

    static async header() {
        const element = 'div[data-home-banner-slider]'
        if (element.length > 0 && !$('body').hasClass('eco-mode-active')) {
            const swiperKey = 'home_banner_swiper'
            const slideItem = '.header__banner__item__content .header__banner__item__metas'

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                slidesPerView: 1,
                centeredSlides: true,
                spaceBetween: 0,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                effect: 'slide',
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                pagination: {
                    el: '.header__banner__item__pagination',
                    clickable: true,
                },
                keyboard: true,
                loop: true,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 1.5,
                    },
                    1000: {
                        slidesPerView: 1.5,
                    },
                },
                on: {
                    init: (swiper) => {
                        $(`.header__banner__item__thumbnail [data-background="${swiper.activeIndex}"]`).addClass('is-active')
                        $('.header__banner__item__metas').addClass('metas-initialized')
                    },
                    transitionStart: (swiper) => {
                        $('.header__banner__item__thumbnail').removeClass('is-active')
                        $(`[data-header-background='${swiper.realIndex}']`).addClass('is-active')

                        $('.header__banner__item__progressbar__item').removeClass('active')
                        $(`[data-header-progressbar='${swiper.realIndex}']`).addClass('active')
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)

            $('.header__banner__item__bulletization__image').on('click', function () {
                const index = $(this).data('index')
                mySwiper.slideTo(index)
            })
        }
    }

    static strateBases() {
        const element = $('.strate-bases')
        if (element.length > 0 && !element.is(':hidden')) {
            // change item on tab click
            $('.strate-bases__tab').on('click', function () {
                const target = $(this).data('bases-key')
                HomeService.goToBaseItem(target)
            })

            // autoplay 8s when strate is visible
            const targetObs = document.querySelector('#strate-bases')
            const observer = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        $('.strate-bases__tab').first().addClass('active')
                        HomeService.changeLabelDropdown()
                        $('.strate-bases__progressbar__item').first().addClass('active')
                        setTimeout(() => {
                            HomeService.goToBaseItem(1)
                        }, '8000')
                    }
                }
            })
            observer.observe(targetObs)

            // dropdown on mobile
            const dropdownCurrent = $('[data-element="base-dropdown-current"]')
            const dropdownList = $('[data-element="base-dropdown-list"]')
            dropdownCurrent.on('click', () => {
                dropdownList.toggleClass('open')
            })
            dropdownList.on('click', () => {
                dropdownList.toggleClass('open')
            })
        }
    }

    static goToNextBaseItem() {
        const current = $('.strate-bases__tab.active').data('bases-key')
        let next = current + 1
        const max = $('.strate-bases__tab').length
        if (max <= next) {
            next = 0
        }
        HomeService.goToBaseItem(next)
    }

    static goToBaseItem(target) {
        // tab
        $('.strate-bases__tab').removeClass('active')
        $(`[data-bases-key="${target}"]`).addClass('active')

        // image
        $('.strate-bases__thumbnail').removeClass('active')
        $(`[data-bases-background="${target}"]`).addClass('active')

        // metas
        $('.strate-bases__item__metas').removeClass('active')
        $(`[data-bases-metas="${target}"]`).addClass('active')

        // progressbar
        $('.strate-bases__progressbar__item').removeClass('active')
        $(`[data-bases-progressbar="${target}"]`).addClass('active')

        HomeService.startAutoplay()
        HomeService.changeLabelDropdown()
    }

    static startAutoplay() {
        if (HomeService.autoplayTimer) {
            clearTimeout(HomeService.autoplayTimer)
        }

        HomeService.autoplayTimer = setTimeout(() => {
            HomeService.goToNextBaseItem()
        }, 8000)
    }

    static changeLabelDropdown() {
        const dropdownCurrentValue = $('[data-element="base-dropdown-current"] .value')
        const currentTabLabel = $('.strate-bases__tab.active .title').html()
        dropdownCurrentValue.html(currentTabLabel)
    }

    static async strateActivites() {
        const element = $('.strate-activites')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-activites-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .strate-activites__card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                effect: 'slide',
                grabCursor: true,
                slidesPerView: 1.25,
                spaceBetween: 10,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 2.25,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 3,
                        spaceBetween: 40,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static strateChiffres() {
        const element = $('.strate-chiffres')
        if (element.length > 0 && !element.is(':hidden')) {
            // start anim on scroll reveal
            const targetObs = document.querySelector('#strate-chiffres')
            const observer = new IntersectionObserver((entries) => {
                for (const entry of entries) {
                    if (entry.isIntersecting) {
                        HomeService.animateNumbers()
                    }
                }
            })
            observer.observe(targetObs)
        }
    }

    static animateNumbers() {
        $('.strate-chiffres__numbers__number .value').each(function () {
            const $this = $(this)
            const countTo = $this.attr('data-count')

            $({ countNum: 0 }).animate(
                { countNum: countTo },
                {
                    duration: 2000,
                    easing: 'linear',
                    step: function () {
                        $this.text(Math.floor(this.countNum))
                    },
                    complete: function () {
                        $this.text(this.countNum)
                        $this.addClass('finished')
                    },
                },
            )
        })
    }

    static async strateBlog() {
        const element = $('.strate-blog')
        if (element.length > 0 && !element.is(':hidden')) {
            const swiperKey = 'home-blog-swiper'
            const slideItem = `[data-swiper="${swiperKey}"] > .strate-blog__card`

            const options = {
                mousewheel: {
                    forceToAxis: true,
                },
                speed: 1000,
                navigation: {
                    nextEl: `#swiper-${swiperKey}-next`,
                    prevEl: `#swiper-${swiperKey}-prev`,
                },
                effect: 'slide',
                grabCursor: true,
                slidesPerView: 1.15,
                spaceBetween: 10,
                freeModeFluid: true,
                passiveListeners: false,
                watchSlidesVisibility: true,
                breakpoints: {
                    600: {
                        slidesPerView: 1.5,
                        spaceBetween: 20,
                    },
                    1000: {
                        slidesPerView: 1.72,
                        spaceBetween: 40,
                    },
                },
            }

            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')

            const mySwiper = await Swiper.create(`[data-swiper="${swiperKey}"]`, options)
        }
    }

    static scrollReveal() {
        const ratio = 0.1
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1,
        }

        const handleIntersect = (entries, observer) => {
            for (const entry of entries) {
                if (entry.intersectionRatio >= ratio) {
                    entry.target.classList.remove('reveal')
                    observer.unobserve(entry.target)
                }
            }
        }

        if (!$('body').hasClass('eco-mode-active')) {
            document.documentElement.classList.add('reveal-loaded')
            if (!window.matchMedia('(max-width: 1000px)').matches) {
                const observer = new IntersectionObserver(handleIntersect, options)
                for (const el of $('.reveal')) {
                    observer.observe(el)
                }
            } else {
                for (let i = 1; i <= 10; i++) {
                    for (const el of $(`.reveal-${i}`)) {
                        el.classList.remove(`reveal-${i}`)
                    }
                }
            }
        }
    }
}
